<template>
    <section id="login-page">
        <div class="container-fluid m-0 p-0">
            <div class="login-top">
                <div class="row" style="margin-right:0px; margin-left:0px;">
                    <div class="col-md-12">
                        <div class="main-logo-title">
                            <img
                                src="../assets/images/recopedia-white.png"
                                alt="logo"
                                width="200"
                            />
                        </div>
                        <h5 class="text-white text-center">Silahkan Daftar</h5>
                        <div class="text-center text-white mt-3 mb-4 mx-auto">
                            <h6>
                                Kamu bisa daftar menggunakan sosial media
                                kamu<br />
                                atau daftar biasa lewat form
                            </h6>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="login-card">
                            <div class="card mx-auto p-4 shadow">
                                <!-- Button Social -->
                                <sosmed
                                    :options="optionsSosmed"
                                    :notif_alert="notif_alert"
                                    :load="loadRegister"
                                    @register_on="onListenRegister"
                                ></sosmed>
                                <!-- End Button Social -->
                                <div class="separator">
                                    <div class="line"></div>
                                    <div
                                        class="
                                            text-center
                                            bg-white
                                            separator-text
                                        "
                                    >
                                        Atau
                                    </div>
                                </div>
                                <!-- Form Login -->
                                <!-- Form Login -->
                                <form action="#" id="login-form">
                                    <div
                                        class="alert alert-danger"
                                        role="alert"
                                        v-bind:style="{
                                            display: notif_alert.alert,
                                        }"
                                    >
                                        {{ notif_alert.alertMessage }}
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="email" class="mb-2"
                                            >Email</label
                                        >
                                        <input
                                            type="text"
                                            v-model="email"
                                            :readonly="isRead"
                                            class="form-control"
                                            id="email"
                                            placeholder="Masukkan Email"
                                        />
                                    </div>
                                    <div class="form-group mb-3">
                                        <label for="password" class="mb-2"
                                            >Password</label
                                        >
                                        <input
                                            type="password"
                                            v-model="pass"
                                            :readonly="isRead"
                                            name="pass-1"
                                            class="form-control"
                                            id="password"
                                            placeholder="Masukkan Password"
                                        />
                                    </div>
                                    <div class="form-group">
                                        <label
                                            for="confirm-password"
                                            class="mb-2"
                                            >Ulangi Password</label
                                        >
                                        <input
                                            type="password"
                                            v-model="pass_2"
                                            :readonly="isRead"
                                            name="pass-2"
                                            class="form-control"
                                            id="confirm-password"
                                            placeholder="Ulangi Password"
                                        />
                                    </div>
                                     <div class="form-group mt-3 d-flex flex-row align-items-start">
                                        <input  type="checkbox"  class="pl-3" id="flexCheckDefault" style="margin-right:10px;margin-top:4px;" v-on:change="handleCheckBox()">
                                        <div class="d-flex flex-column">
                                               <p>Dengan mendaftar & masuk, Saya mengkonfirmasi bahwa data yang diberikan adalah benar. Data Anda tersimpan aman di dalam layanan jaringan kami sesuai dengan informasi yang diberikan.</p>
                                            <div class="has-error" style="color:red;" v-if="!optionsSosmed.isCheckbox">Anda harus setuju sebelum login.</div>
                                        </div>
                                    </div>
                                    <div class="d-grid my-3">
                                        <button
                                            v-if="loadRegister"
                                            disabled
                                            class="btn btn-main-login"
                                        >
                                            <i
                                                class="fa fa-spinner fa-spin"
                                            ></i>
                                            DAFTAR SEKARANG
                                        </button>
                                        <button
                                            v-else
                                            type="button"
                                            class="btn btn-main-login"
                                            v-on:click="register()"
                                        >
                                            DAFTAR SEKARANG
                                        </button>
                                    </div>
                                    <div class="mt-4 link-help">
                                        Anda sudah punya akun? silahkan
                                        <span class="fw-bold"
                                            ><router-link to="/login"
                                                >Masuk Disini</router-link
                                            ></span
                                        >
                                    </div>
                                </form>
                                <!-- End Form Login -->
                            </div>
                        </div>
                    </div>
                    <div class="col-12 text-center mt-3 pt-5 fw-bold mb-5">
                        <footer>
                              <!-- <img src="../assets/images/anhi-logo-OG.png" style="width:150px;"/> -->
                        </footer>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { getError } from '@/utils/helpers';
import AuthService from '@/services/AuthService';
import Sosmed from '@/components/Sosmed';

export default {
    name: 'RegisterView',
    components: { Sosmed },
    data() {
        return {
            loadRegister: false,
            isRead: false,
            error: null,
            email: '',
            pass: '',
            pass_2: '',
            notif_alert: {
                alert: 'none',
                alertMessage: '',
            },
            optionsSosmed: {
                type: 'register',
                isCheckbox:false,
            },
        };
    },
    created() {
        document.title = 'Daftar - Recopedia'
    },
    methods: {
        async register() {
             if(this.optionsSosmed.isCheckbox == false) {
                // this.notif_alert.alertType = 'alert-danger';
                // this.notif_alert.alert = 'block';
                // this.notif_alert.alertMessage = 'Mohon, untuk menceklsi bagaian ijin';
                this.loadLogin = false;
                this.isRead = false;
                return;
            }

            this.loadRegister = true;
            this.isRead = true;
            const payload = {
                email: this.email,
                password: this.pass,
                password_confirmation: this.pass_2,
                type: 'dokter',
            };
            await AuthService.register(payload)
                .then((response) => this.onResponse(response))
                .catch((error) => getError(error));
        },
        onResponse(response) {
            if (response.data.result == 'error') {
                this.notif_alert.alert = 'block';
                this.notif_alert.alertMessage = response.data.message;
                this.loadRegister = false;
                this.isRead = false;
            } else {
                //   this.$store.dispatch("auth/setMessage",{value:response.data.message})
                //   this.$router.push({name:"login"})
                this.login();
            }
        },
        async login() {
            const payload = {
                email: this.email,
                password: this.pass_2,
                device_name: 'web',
            };
            this.error = null;
            try {
                const response = await AuthService.login(payload);
                if (response.data.result == 'success') {
                    const token = response.data.token.split('|')[1];
                    this.$store.dispatch('auth/setToken', { value: token });
                    this.$store.dispatch('auth/setEmail', {
                        value: this.email,
                    });

                    await this.$store.dispatch('auth/getAuthUser');
                    this.$store.dispatch('auth/setMenuActive', {
                        value: 'dashboard',
                    });
                    this.$store.dispatch('auth/setMessage', { value: '' });
                    this.$router.push('/dashboard');
                    this.loadRegister = false;
                    this.isRead = false;
                } else {
                    this.notif_alert.alertType = 'alert-danger';
                    this.notif_alert.alert = 'block';
                    this.notif_alert.alertMessage = response.data.message;
                    const error = Error(
                        'Unable to fetch user after login, check your API settings.'
                    );
                    this.loadRegister = false;
                    this.isRead = false;
                    error.name = 'Fetch User';
                    throw error;
                }
            } catch (error) {
                this.error = getError(error);
            }
        },
        onListenRegister(val) {
            this.isRead = val;
            this.loadRegister = val;
        },
        handleCheckBox() {
            if(this.optionsSosmed.isCheckbox) {
                this.optionsSosmed.isCheckbox = false;
            } else {
                this.optionsSosmed.isCheckbox = true;
            }
        }
    },
};
</script>
<style scoped>
#pic .pic-page .stats-card .card.active {
    background-color: #4318ff !important;
    border-radius: 8px !important;
}
</style>